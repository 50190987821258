<template>
  <div class="platform-event">
    <feed-detail-banner
      title="Event details"
      :is-public="isPublic"
    /> 
    <div class="container">
      <el-row :gutter="0">
        <el-col
          :xl="17"
          :lg="17"
          :md="24"
          :sm="24"
          :xs="24"
          style="position: relative"
        >
          <div class="left-side">
            <div
              v-show="loading"
              v-loading="loading"
              :class="{ loading: loading }"
            ></div>
            <div v-if="event">
              <h1 class="title">
                {{ event.title }}
              </h1>

              <div class="event-info">
                <div class="userInfo-and-more">
                  <el-row>
                    <el-col
                      :xl="12"
                      :md="12"
                      :sm="12"
                      :xs="18"
                    >
                      <div class="grid-content name-date">
                        <img
                          :src="event.author_avatar_url"
                          :alt="event.author"
                        />
                        <span class="text">{{ event.author }}</span>
                        <span class="text date-creation">{{ event.published_at }}</span>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div
                  v-if="event"
                  class="event-content reset-this"
                >
                  <div class="event-details">
                    <el-row>
                      <el-col
                        :xl="12"
                        :lg="12"
                        :md="12"
                        :sm="12"
                        :xs="24"
                      >
                        <h2>Event details</h2>
                        <p>{{ event.start_date }}, {{ event.start_time }}</p>
                        <p v-if="!event.same_day">
                          to {{ event.end_date }}, {{ event.end_time }}
                        </p>
                        <p>{{ event.address_line_1 }}</p>
                        <p v-if="event.address_line_2">
                          {{ event.address_line_2 }}
                        </p>
                        <p>{{ event.city }}</p>
                        <p>{{ event.postcode }}</p>
                      </el-col>
                      <el-col
                        v-if="!isPublic && !event.reminders.data.length && !eventPassed"
                        :xl="12"
                        :lg="12"
                        :md="12"
                        :sm="12"
                        :xs="24"
                      >
                        <h2>Set a reminder</h2>
                        <span>Get a notification to your app, set the time you'd like to be reminded before the event</span>
                        <div class="choose-day">
                          <el-col
                            :xl="12"
                            :lg="24"
                          >
                            <el-select
                              v-model="reminderValue"
                              placeholder="Select"
                            >
                              <el-option
                                v-for="item in options"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                              >
                              </el-option>
                            </el-select>
                          </el-col>
                          <el-col
                            :xl="12"
                            :lg="12"
                          >
                            <button
                              class="fgh-button fgh-button--primary"
                              @click="toggleReminder(false, true)"
                            >
                              Add reminder
                            </button>
                          </el-col>
                        </div>
                      </el-col>
                      <el-col
                        v-if="!isPublic && !editReminder && event.reminders.data.length > 0"
                        :xl="12"
                        :lg="12"
                        :md="12"
                        :sm="12"
                        :xs="24"
                      >
                        <h2>Your Reminder</h2>
                        <span>Reminder set for</span>
                        <div class="chosen-day">
                          <el-col
                            :xl="12"
                            :lg="24"
                          >
                            <div class="day-value">
                              <span>{{ hoursToDay(event.reminders.data[0].hours_before) }} day</span>
                            </div>
                          </el-col>
                          <el-col
                            :xl="12"
                            :lg="12"
                          >
                            <div class="actions">
                              <span @click="editReminder = true">Edit</span>
                              <span class="line">|</span>
                              <span @click="toggleReminder(false, false)">Remove</span>
                            </div>
                          </el-col>
                        </div>
                      </el-col>
                      <el-col
                        v-if="editReminder"
                        :xl="12"
                        :lg="12"
                        :md="12"
                        :sm="12"
                        :xs="24"
                      >
                        <h2>Update Reminder</h2>
                        <span>Get a notification to your app, set the time you'd like to be reminded before the event</span>
                        <div class="choose-day">
                          <el-col
                            :xl="12"
                            :lg="24"
                          >
                            <el-select
                              v-model="reminderHours"
                              placeholder="Select"
                            >
                              <el-option
                                v-for="item in options"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                              >
                              </el-option>
                            </el-select>
                          </el-col>
                          <el-col
                            :xl="12"
                            :lg="12"
                          >
                            <button
                              class="fgh-button fgh-button--primary"
                              @click="toggleReminder(true, true)"
                            >
                              Update
                            </button>
                          </el-col>
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                  <div class="desc">
                    <div class="desc">
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <p v-html="event.content"></p>
                    </div>
                  </div>
                </div>
                <!-- FOOTER -->
                <div class="footer">
                  <span>Did you enjoy this event?</span>
                  <like-button
                    :has-liked="event.likes.has_liked"
                    :likeable-type="event.feed_key"
                    :likeable-id="event.id"
                    @on-liked-toggled="event.likes = $event"
                  />
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col
          :xl="7"
          :lg="7"
          class="hidden-md-and-down"
        >
          <sidebar
            v-if="event"
            :tags="event.tags.data"
            :feed-key="event.feed_key"
            :is-public="isPublic"
            :is-related="true"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import feedApi from '@/modules/feed/services/feed.api'
import accountApi from '@/modules/account/services/account.api'
import FeedDetailBanner from '@/modules/feed/components/feed-detail-banner'
import Sidebar from '@/modules/feed/components/sidebar'
import LikeButton from '@/modules/feed/components/like-button'

export default {
  components: {
    FeedDetailBanner,
    Sidebar,
    LikeButton,
  },

  props: {
    isPublic: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    event: null,
    loading: false,
    likeLoading: false,
    editReminder: false,
    options: [],
    reminderValue: '',
    reminderHours: ''
  }),

  computed: {
    eventPassed() {
      if (this.event) {
        return moment(this.event.start_date_string).isBefore(moment())
      }

      return false
    }
  },

  watch: {
    '$route.params.id' (id) {
      this.event = null
      this.fetchEvent()
    }
  },

  created() {
    this.fetchEvent()
    if (!this.isPublic) {
      this.getReminderOptions()
    }
  },

  methods: {
    fetchEvent() {
      this.loading = true

      feedApi.event(this.$route.params.id, this.isPublic)
        .then(response => {
          this.loading = false
          this.event = response.data
          if (!this.isPublic) {
            this.updatePoints()
            if (response.data.reminders.data.length > 0) {
              this.reminderValue = response.data.reminders.data[0].hours_before
              this.reminderHours = response.data.reminders.data[0].hours_before
            } else {
              this.reminderValue = ''
              this.reminderHours = ''
            }
          }
        })
        .catch(() => {
          this.$router.push({ name: 'feed-feed' })
        })
    },
    getReminderOptions() {
      feedApi.reminderOptions()
        .then(response => {
          if (response.data) {
            this.options = response.data.data
          }
        })
    },
    toggleReminder(updating = false, setting = true) {
      if (this.reminderValue || this.reminderHours || !setting) {
        feedApi[setting ? 'setReminder' : 'unsetReminder']({
          module: "Event",
          entity: "Event",
          id: this.$route.params.id,
          option_id: updating ? this.reminderHours : this.reminderValue
        }).then(response => {
          this.fetchEvent()

          if (updating) {
            this.editReminder = false
          }
        })
      }
    },
    hoursToDay(hours) {
      return Math.floor(hours / 24);
    },
    updatePoints() {
      accountApi
        .gamers()
        .then(response => {
          this.$store.commit("UPDATE_POINTS", response.data);
        })
    },
    likeUnlike(hasLiked) {
      if (!this.$store.state.isLogged) {
        this.$router.push({ name: 'auth-login' })
      }

      this.likeLoading = true

      feedApi[hasLiked ? 'unlike' : 'like']({
        "module": this.event.feed_key,
        "entity": this.event.feed_key,
        "id": `${this.event.id}`
      }).then(response => {
        this.likeLoading = false
        this.event.likes = response.data
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.platform-event {
  @media (max-width: 991px) {
    overflow: hidden;
  }
  .left-side {
    //min-height: 1350px;
    position: relative;
    background-color: $white-color;
    padding: 0 65px 42px 0;
    &:before {
      content: "";
      position: absolute;
      background-color: #fff;
      left: -90%;
      width: 90%;
      height: 100%;
      @media (max-width: 991px) {
        content: "";
        position: absolute;
        background-color: #fff;
        left: -20%;
        width: 140%;
        height: 100%;
        z-index: -1;
      }
    }
    .loading {
      min-height: 300px;
      width: 100%;
    }
    .title {
      margin-top: 0;
      padding-top: 28px;
      @media (max-width: 575px) {
        font-size: 36px;
        line-height: 40px;
      }
      @media (max-width: 440px) {
        font-size: 24px;
      }
    }
    .userInfo-and-more {
      padding: 14px 0 12px 0;
      border-top: 1px solid;
      border-bottom: 1px solid;
      border-color: $border-color;
      @media (max-width: 375px) {
        .el-col {
          width: 100%;
        }
      }
      .name-date {
        display: flex;
        height: 41px;
        line-height: 41px;
        img {
            width: 38px;
            height: 38px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: inline-block;
            border-radius: 50%;
            margin-left: 0;
        }
        .text {
          display: inline-block;
          margin-left: 10px;
          font-size: 12px;
          font-weight: bold;
        }
        .date-creation {
          position: relative;
          padding-left: 30px;
          &:before {
            content: '|';
            position: absolute;
            right: 80px;
            color: #979797;
          }
        }
      }
      .share-btn {
        text-align: right;
        padding-right: 35px;
        button {
          background-color: transparent;
          vertical-align: sub;
          img  {
            vertical-align: middle;
            border-radius: inherit;
          }
          span {
            margin-left: 5px;
            vertical-align: middle;
          }
        }
      }
    }
    .event-details {
      border-bottom: 1px solid #D8D8D8;
      padding: 30px 0;
      p {
        margin: 0;
      }
      .choose-day {
        margin-top: 25px;
        overflow: auto;
        @media screen and (max-width: 575px) {
          .el-col:last-child {
            text-align: center;
          }
        }
        button {
          position: relative;
          margin-left: 28px;
        }
      }
      .chosen-day {
        overflow: auto;
        //display: flex;
        //align-items: center;
        margin-top: 15px;
        padding: 5px;
        border: 1px solid #9d9d9d;
        border-radius: 4px;
        .day-value {
          background-color: #ececec;
          padding: 10px 20px;
          border-radius: 2px;
        }
        .actions {
          text-align: right;
          font-size: 14px;
          padding: 10px 20px;
          span {
            margin-right: 5px;
            cursor: pointer;
            &:last-child {
              margin: 0;
            }
            .line {
              cursor: auto;
            }
          }

        }
      }
    }
    .desc {
      p {
        margin: 0 auto 30px auto;
        font-size: 18px;
        &:first-child {
          margin-top: 32px;
        }
      }
    }
    .footer {
      display: flex;
      align-items: center;
      margin-top: 56px;
      padding-bottom: 45px;
      font-size: 18px;
    }
  }
}
@media screen and (max-width: 768px) {
  .platform-event {
    .left-side {
      width: 100%;
    }
    .footer {
      text-align: center;
      .like-btn {
        margin-top: 20px;
        text-align: center !important;
      }
    }
  }

}
@media screen and (max-width: 674px) {
  .platform-event {
    .left-side {
      padding: 0 5px;
      .userInfo-and-more {
        .name-date {
          .name {
            text-align: left;
          }
          .date {
            border: none;
            text-align: right;
          }
        }
        .share-btn {
          text-align: center;
          padding: 0;
        }
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .platform-event {
    .left-side {
      .userInfo-and-more {
        text-align: center;
        .name-date {
          display: inherit;
          img {
            vertical-align: middle;
          }
        }
      }
    }
  }
}
</style>
